<template>
  <div class="container">
    <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
                  @play="onPlayerPlay($event)"
                  @pause="onPlayerPause($event)"
                  @canplay="onPlayerCanplay($event)">
    </video-player>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import { getVideoRes } from '../../services/video'
export default {
  name: 'VideoPlayer',
  props: {
    vid: String,
    videoUrl: String,
    poster: String,
    aspectRatio: {
      type: String,
      default: '10:6'
    }
  },
  data () {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放
        muted: false, // 默认情况下将会消除任何音频
        loop: false, // 导致视频一结束就重新开始
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据.auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: this.aspectRatio, // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: this.videoUrl, // 你的m3u8地址（必填）
            withCredentials: false
          }
        ],
        hls: true, // 启用hls
        poster: this.poster, // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  components: {
    videoPlayer
  },
  methods: {
    onPlayerPlay (player) {
      this.$emit('play', player)
    },
    onPlayerPause (player) {
      window.console.log(player)
    },
    onPlayerCanplay (player) {
      this.$emit('canplay', player)
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  watch: {
    vid (newValue, oldValue) {
      window.console.log(oldValue)
      if (newValue) {
        getVideoRes(newValue).then(res => {
          if (res.code === 0) {
            let data = res.data
            this.playInfoList = data.playInfoList
            this.playerOptions.poster = this.poster
            if (data.playInfoList.length > 1) {
              if (this.$store.state.user.token) {
                this.playerOptions.sources[0].src =
                  data.playInfoList[0].playUrl + '?MtsHlsUriToken=' + this.$store.state.user.token
              } else {
                this.playerOptions.sources[0].src =
                  data.playInfoList[0].playUrl
              }
            }
          }
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  background-color: white;
  min-height: 50%;
}
div ::v-deep .video-js .vjs-big-play-button {
  /*
     播放按钮换成圆形
    */
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
}
div ::v-deep .vjs-poster {
  background-size: cover;
}
</style>
