import { get } from './http'

/**
 * 获取点播playauth
  */
export const getVideoRes = (aliVid) => get('tool/vod/' + aliVid, {})


/**
 * 阿里云封面编辑
 */
export const aliEditVideoPic = (params) => get('tool/vod/update/coverurl', params)
